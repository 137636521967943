type GAEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

//for logging pageView event on route change
const pageView = (url: URL): void => {
  window && window.ga && window.ga("send", "pageview", url);
};

// log specific events happening.
const event = ({ action, category, label, value }: GAEvent): void => {
  window &&
    window.ga &&
    window.ga("send", "event", category, action, label, value);
};

export const googleAnalytics = {
  pageView,
  event
};
