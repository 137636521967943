import { BannerLoader } from "components/loader/components";
import { SearchBarLoader } from "components/search-bar/search-bar-loader/search-bar-loader";
import { VideoCardLoader } from "components/video-card/video-card-loader/video-card-loader";
import {
  TanyaSearchContainer,
  TanyaSearchSidebar,
  TanyaSearchVideoCardWrapper
} from "styles/pages/tanya-search.styles";
import { SearchPageContainer } from "./search-page-loader.styles";

const videoCardCount = 3;

const SearchPageLoader: React.FC = () => (
  <div aria-label={"search-page-loader"}>
    <SearchBarLoader />
    <SearchPageContainer>
      <TanyaSearchContainer>
        <TanyaSearchVideoCardWrapper>
          <VideoCardLoader id="search-page-loader" count={videoCardCount} />
        </TanyaSearchVideoCardWrapper>
        <TanyaSearchSidebar>
          <BannerLoader />
        </TanyaSearchSidebar>
      </TanyaSearchContainer>
    </SearchPageContainer>
  </div>
);

export { SearchPageLoader };
