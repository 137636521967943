import React from "react";
import { LoaderPageName, LoaderProps } from "./loader.types";
import { CategoryPageLoader } from "./pages/category-page-loader/category-page-loader";
import { SearchPageLoader } from "./pages/search-page-loader/search-page-loader";
import { VideoPageLoader } from "./pages/video-page-loader/video-page-loader";

const Loader = ({ loadingPageName }: LoaderProps): JSX.Element => {
  if (loadingPageName === LoaderPageName.tanyaCategory)
    return <CategoryPageLoader />;
  else if (loadingPageName === LoaderPageName.tanyaSearch)
    return <SearchPageLoader />;
  else if (loadingPageName === LoaderPageName.tanyaVideo)
    return <VideoPageLoader />;
  // TODO: Add deafult loader once the design is finalized
  return <></>;
};

export { Loader };
