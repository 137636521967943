import { SearchBarContainer } from "components/search-bar/search-bar.styles";
import styled from "styled-components";
import { sizes } from "styles";
import { IconButton } from "styles/common/icon-button";
import { breakpoints } from "../constants/breakpoints";

export const TanyaSearchContainer = styled.main`
  margin: 30px 0;
  @media ${breakpoints.tablet} {
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-gap: 32px;
    margin: 40px 0;
  }
`;
export const TanyaSearchVideoListContainer = styled.section``;
export const TanyaVerifiedVideoSnackbarContainer = styled.div`
  position: relative;
  margin: 0 -1rem;
  @media ${breakpoints.laptop} {
    margin: unset;
  }
`;
export const TanyaSearchSidebar = styled.aside`
  display: none;
  @media ${breakpoints.tablet} {
    display: block;
  }
`;

export const TanyaSearchBannerImageLink = styled.a`
  display: block;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 16px;

  @media ${breakpoints.tablet} {
    margin: 0 0 16px 0;
  }
`;

export const TanyaSearchVideoCardWrapper = styled.div`
  margin-bottom: 16px;

  @media ${breakpoints.tablet} {
    margin-bottom: 48px;
  }
`;

export const TanyaMobileBanner = styled.div`
  display: block;

  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export const LiveClassesMobileBanner = styled.div`
  display: block;
  width: 300px;

  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.brand[500]};
  margin-left: 5px;
`;

export const FilterIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  background: transparent;
  white-space: nowrap;
  margin-left: 8px;

  @media ${breakpoints.laptop} {
    display: none;
  }
`;

export const TanyaSearchBarContainer = styled.div`
  display: flex;
  max-width: ${sizes.containerWidth};
  box-shadow: 0 4px 9px ${({ theme }) => theme.colors.neutral[900] + "0d"};
  padding: 0 16px;
  gap: 12px;

  @media ${breakpoints.laptop} {
    margin: 0 auto;
    width: 100%;
    box-shadow: unset;
    ${SearchBarContainer} {
      width: 100%;
      margin: 8px auto;
    }
  }
`;

export const TanyaVideoPageContainer = styled.div<{ disableClicks?: boolean }>`
  pointer-events: ${({ disableClicks }) => (disableClicks ? "none" : "auto")};
  touch-action: ${({ disableClicks }) => (disableClicks ? "none" : "auto")};
  overflow-x: hidden;
`;
