import styled from "styled-components";
import { breakpoints } from "styles";
import { VideoListContainer } from "styles/common/container";

export const SearchPageContainer = styled(VideoListContainer)`
  @media ${breakpoints.tablet} {
    width: 97%;
  }

  @media ${breakpoints.laptop} {
    margin-top: 100px;
    width: 100%;
  }
`;
