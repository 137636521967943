import { ToastProvider } from "components/atoms/toast/toast-context";
import { Layout } from "components/layout/layout";
import { Loader } from "components/loader/loader";
import AuthGuard from "hoc/AuthGuard";
import { useLoading } from "hooks/pages/use-loading/use-loading";
import { useGetCurrentPlatform } from "hooks/use-media-query/use-get-currrent-platform";

import { SessionProvider } from "next-auth/react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { googleAnalytics } from "services/google-analytics";
import {
  EventPayload,
  registerSuperProperties
} from "services/mixpanel/mixpanel";
import { AuthProvider } from "stores/auth/auth-context";
import { KelasLiveContextProvider } from "stores/kelas-live/kelas-live-context";
import { TanyaProvider } from "stores/tanya/tanya-context";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "styles";
import { AppProps } from "types/next-auth";
import { GrowthBookContextProvider } from "../stores/growthbook/growthbook-context";

const mixPanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
const mixPanelDebug = process.env.NEXT_ENV != "production" ? true : false;

export const loadMixpanel = async () => {
  const mixpanel = (await import("mixpanel-browser")).default;
  mixPanelToken && mixpanel.init(mixPanelToken, { debug: mixPanelDebug });
};

export const batchEvents: Array<{ eventName: string; payload?: EventPayload }> =
  [];

const CoLearnApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const currentPlatform = useGetCurrentPlatform();

  const { isLoading, loadingPageName } = useLoading();
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      googleAnalytics.pageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    loadMixpanel().then(() => {
      registerSuperProperties({
        platform: currentPlatform
      });
    });
  }, []);

  const isTanyaVideoPage = router.pathname === "/tanya/[questionId]/[slug]";

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      {/* Google Analytics */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        strategy="lazyOnload"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag("config", "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}");
        `}
      </Script>

      {!isTanyaVideoPage && (
        <StyleSheetManager shouldForwardProp={() => true}>
          <AuthProvider>
            <SessionProvider session={pageProps.session}>
              <ThemeProvider theme={theme}>
                <KelasLiveContextProvider>
                  <GrowthBookContextProvider>
                    <TanyaProvider>
                      <GlobalStyle theme={theme} />
                      <ToastProvider>
                        {Component.requireAuth ? (
                          <AuthGuard>
                            <Layout pageProps={pageProps}>
                              <Component {...pageProps} />
                            </Layout>
                          </AuthGuard>
                        ) : (
                          <Layout pageProps={pageProps}>
                            {isLoading ? (
                              <Loader loadingPageName={loadingPageName} />
                            ) : (
                              <Component {...pageProps} />
                            )}
                          </Layout>
                        )}
                      </ToastProvider>
                    </TanyaProvider>
                  </GrowthBookContextProvider>
                </KelasLiveContextProvider>
              </ThemeProvider>
            </SessionProvider>
          </AuthProvider>
        </StyleSheetManager>
      )}
      {isTanyaVideoPage && (
        <ThemeProvider theme={theme}>
          <TanyaProvider>
            <GlobalStyle theme={theme} />
            <ToastProvider>
              <Layout pageProps={pageProps}>
                {isLoading ? (
                  <Loader loadingPageName={loadingPageName} />
                ) : (
                  <Component {...pageProps} />
                )}
              </Layout>
            </ToastProvider>
          </TanyaProvider>
        </ThemeProvider>
      )}
    </>
  );
};

export default CoLearnApp;
