import styled from "styled-components";
import { breakpoints } from "styles";
import { IconButton } from "styles/common/icon-button";

export const SearchContainer = styled.div<{ isFullWidth?: boolean }>`
  width: ${(isFullWidth) => (isFullWidth ? "100%" : "80%")};
  margin: ${(isFullWidth) => (isFullWidth ? "0 auto" : "initial")};
`;

export const ErrorContainer = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-left: 20px;
  margin-top: 0;
  @media ${breakpoints.laptop} {
    margin-left: 0;
  }
  color: ${({ theme }) => theme.colors.red[100]};
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

export const SearchBarContainer = styled.div<{ error: string | null }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 3px solid
    ${({ theme, error }) => {
      return error ? theme.colors.red[100] : theme.colors.neutral[200];
    }};
  margin: 8px auto;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  @media ${breakpoints.tablet} {
    padding: 12px 20px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.neutral[900]};
  word-wrap: break-word;
  word-break: break-all;
  height: 16px;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral[300]};
  }

  &:focus {
    outline: none;
  }

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.md};
    height: 20px;
  }
`;

export const CloseIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.neutral[900]};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
  @media ${breakpoints.tablet} {
    margin-right: 16px;
  }
`;

export const SearchIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.neutral[900]};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &::before {
    content: "";
    width: 3px;
    height: 28px;
    background: ${({ theme }) => theme.colors.neutral[200]};
    margin-right: 8px;

    @media ${breakpoints.tablet} {
      margin-right: 16px;
      height: 22px;
    }
  }
`;
