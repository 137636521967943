export interface IGetSessionsForUserResponse {
  data: ISessionsAPIData;
  errors: null;
  message: string;
  meta: Meta;
}

export interface ISessionsAPIData {
  sessionsPerCourse: string;
  practiseSheetPerCourse: string;
  studentType: string;
  currentTimeStamp: string;
  mediaBaseUrl: string;
  courses: Course[];
}

export interface Course {
  id: string;
  type: string;
  name: string;
  inclusions: string;
  imageThumbnailUrl: string;
  videoUrl: string;
  videoThumbnailUrl: string;
  bannerUrl: string;
  startDate: string;
  endDate: string;
  syllabus: string;
  isDeleted: boolean;
  status: string;
  tags: null;
  pricing: Pricing;
  isOptional: boolean;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  slot: Slot[];
}

export interface Pricing {
  slotId: string;
  listPrice: number;
  currentPrice: number;
}

export interface Slot {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  seatCapacity: number;
  currentlyFilled: number;
  listPrice: number;
  currentPrice: number;
  lastEnrollmentDate: string;
  discountPercentage: number;
  isDeleted: boolean;
  status: string;
  schedule: Schedule;
  courseId: string;
  productId: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  session: ClassSession[];
  practiseSheet: Array<string>[];
}

export interface Schedule {
  days: string[];
  endTime: string;
  startTime: string;
}

export interface StudyMaterialURL {
  url: string;
  fileName: string;
}

export interface UpcomingClassSession {
  mandatoryClasses: ClassSession[];
  extraClasses: ClassSession[];
}

export interface ClassSession {
  id: string;
  type: string;
  startDate: string;
  duration: number;
  topic: string;
  description: string;
  subject: string;
  tags: string[] | null;
  bannerUrl: string;
  conceptVideos: ClassSessionVideoMaterial[];
  overviewVideo: ClassSessionVideoMaterial | null;
  studyMaterialUrls: StudyMaterialURL[] | null;
  seatAvailability: number;
  seatVacancy: boolean;
  isDeleted: boolean;
  isOptional: boolean;
  endDate: string;
  teacherId: string;
  slotId: string;
  courseId: string;
  meetingScheduleId: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  teacher: Teacher;
  participant?: Array<Record<string, string>>;
}

export interface ClassSessionVideoMaterial {
  url: string;
  thumbnailUrl: string;
}

export interface Teacher {
  id: string;
  teacherId: string;
  name: string;
  email: string;
  phoneNumber: string;
  avatarUrl: string;
  gender: string;
  description: string;
  subjects: Subjects;
  videoUrl: string;
  videoThumbnailUrl: string;
  badge: null;
  qualification: null;
  university: string;
  experience: string;
  location: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
}

export interface Subjects {
  expertIn: string[];
}

interface Meta {
  length: number;
  took: number;
  total: number;
}

export interface ISubscriptionStatusResponse {
  data: SubscriptionStatusData;
  errors: null;
  message: string;
  meta: Meta;
}

interface SubscriptionStatusData {
  subscriptionStatus: SubscriptionStatus;
  tanya_subscription: TanyaSubscriptionData;
}

export enum SubscriptionType {
  PAID = "PAID",
  FREE = "FREE",
  TRIAL = "TRIAL",
  FREE_AFTER_TRIAL = "FREE_AFTER_TRIAL",
  FREE_AFTER_PAID = "FREE_AFTER_PAID",
  PAUSED = "PAUSED"
}

export interface SubscriptionStatus {
  id: string;
  daysLeft: number;
  trialEndDate: string;
  message: string | null;
  subscriptionType: SubscriptionType;
  subscribedCourses: SubscribedCourse[];
  pendingPackageSubscription?: PendingPackageSubscription | null;
}

export interface SubscribedCourse {
  course: {
    name: string;
  };
  courseId: string;
  slotId: string;
}

export interface IFocusExamResponse {
  data: IFocusExamData;
  errors: null;
  message: string;
  meta: Meta;
}

export interface IFocusExamData {
  focusExams: FocusExam[];
}

export interface FocusExam {
  id: string;
  name: string;
  isDeleted: boolean;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface IGetZoomURLResponse {
  data: GetZoomURLData;
  message: string;
  meta: Meta | null;
  errors: null;
}

export interface GetZoomURLData {
  platform: string;
  join_url: string;
  platform_settings: GetZoomURLPlatformSettings;
  qna_token: string;
}

export interface GetZoomURLPlatformSettings {
  registration: boolean;
}

export interface IActivateTrialForStudentResponse {
  data: IActivateTrialForStudentData;
}

export interface IGetPastSessionsForUserResponse {
  data: IPastSessionsAPIData;
  errors: null;
  message: string;
  meta: Meta;
}

export interface IActivateTrialForStudentData {
  trialStartDate: string;
  trialEndDate: string;
}

export interface IPastSessionsAPIData {
  sessions: ClassSession[];
  count: number;
  hasNext: boolean;
  currentTimeStamp: string;
  mediaBaseUrl: string;
}

export interface IUserPurchasesResponse {
  id: string;
  payment_info: PaymentInfo;
  package_details: PackageDetails;
  product_id: string;
  subscribed_slots: SubscribedSlot[];
  active: boolean;
  live_class_begins_at: string;
  tenure_type: string;
  tenure_starts_at: string;
  tenure_ends_at: string;
  package_thumbnail: PackageThumbnail;
  media_base_url: string;
}

export interface PackageDetails {
  id: string;
  name: string;
  description: string;
  inclusions: string;
}

export interface PackageThumbnail {
  url: string;
}

export interface PaymentInfo {
  status: string;
  method: string;
  transaction_data: string;
  coupon_used: null;
  ppn: number;
  discounted_amount: number;
  price: number;
  paid_price: number;
  currency: string;
}

export interface SubscribedSlot {
  id: string;
  name: string;
  seat_capacity: number;
  course: Course;
  days: string[];
  status: string;
  start_time: string;
  end_time: string;
  start_date: string;
  end_date: string;
}

// free session api data
export interface IFreeSessionsAPIResponse {
  data: IFreeSessionsAPIData;
  errors: null;
  message: string;
  meta: Meta;
}
export interface IFreeSessionsAPIData {
  sessions: ClassSession[];
  title: string;
  currentTimeStamp: string;
  mediaBaseUrl: string;
}

export interface IGetContentForPausedUserResponse {
  data: ContentForPausedUserData;
  meta: ContentMetaForPausedUser;
  status: string;
}

export interface ContentForPausedUserData {
  subscription_paused_popup: SubscriptionPausedPopup;
  subscription_paused_followup_popup: SubscriptionPausedFollowupPopup;
  live_class_home_banner: LiveClassHomeBanner;
  student_report_banner: LiveClassHomeBanner;
  study_package_toast: LiveClassHomeBanner;
}

export interface LiveClassHomeBanner {
  image: SubscriptionPausedImage;
  title: string;
}

interface SubscriptionPausedImage {
  svg: string;
  png: string;
}

export interface SubscriptionPausedFollowupPopup {
  image: SubscriptionPausedImage;
  title: string;
  subtitle: string;
}

export interface SubscriptionPausedPopup {
  image: SubscriptionPausedImage;
  title: string;
  description: string;
  top_info: SubscriptionPausedTopInfo;
  bottom_info: SubscriptionPausedBottomInfo;
}

export interface SubscriptionPausedBottomInfo {
  description: string;
  link_text: string;
  deep_link: string;
}

export interface SubscriptionPausedTopInfo {
  description: string;
  bank_logo: SubscriptionPausedImage;
  bank_name: string;
}

export interface ContentMetaForPausedUser {
  media_base_url: string;
}

export interface ICreateSupportIssueV2Payload {
  action: string;
  phone_number: string;
  source: string;
  parent_phone_number: string;
  parent_name: string;
}

export interface ISupportIssueAPIResponse {
  data: ISupportIssueAPIData;
}

export interface ISupportIssueAPIData {
  id: string;
  phone_number: string;
  source: string;
  action: string;
  severity: string;
  status: string;
  user_id: string;
  platform_info_id: string;
  created_at: Date;
  updated_at: Date;
}

export interface IGetStudentSessionsBySlotResponse {
  data: Data;
  errors: null;
  message: string;
  meta: Meta;
}

export interface Data {
  studentType: string;
  currentTimeStamp: Date;
  mediaBaseUrl: string;
  courses: Course[];
}

export interface ISessionAPIData {
  data: {
    session: ClassSession;
    currentTimeStamp: string;
    mediaBaseUrl: string;
  };
  errors: null;
  message: string;
  meta: Meta;
}

export interface ISessionModalConfigurationResponse {
  show_welcome_modal: boolean;
  show_thank_you_modal: boolean;
}

export interface ISessionModalConfigurationResponseWithAPIandState {
  api: {
    show_welcome_modal: boolean;
    show_thank_you_modal: boolean;
  };
  state: {
    show_welcome_modal: boolean;
    show_thank_you_modal: boolean;
  };
}

interface TanyaSubscriptionData {
  video_watch_threshold: number;
  variation: string;
  is_eligible: boolean;
  subscription_info: TanyaSubscriptionInfo[] | null;
}

export enum TanyaSubscriptionStatus {
  ACTIVE = "active",
  EXPIRED = "expired"
}

export interface TanyaSubscriptionInfo {
  created_at: string;
  start_date: string;
  end_date: string;
  id: string;
  payment_id: string;
  payment_method: string;
  product_id: string;
  status: TanyaSubscriptionStatus;
  cancelled_at: string;
}

export interface PendingPackageSubscription {
  packageSubscriptionId: string;
  packageId: string;
  packageTenureId: string;
  pendingCourseIds: string[];
}
